import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import clsx from 'clsx';
import Image from '../../utils/OptimizedImage';
import TrustbarStyles from '../../styles/Components/Trustbar/TrustbarStyles';
import Icon from '../../styles/atoms/icons';

const BACKGROUND_MAPPING = {
  White: 'bg-white',
  Blue: 'bg-blue',
  Black: 'bg-black',
};

const Trustbar = ({ component }) => {
  const determineBackgroundClass = () =>
    component ? BACKGROUND_MAPPING[component?.bgColor] : '';
  const {
    heading,
    headingKicker,
    showHeadingKicker,
    subhead,
    items,
    variant,
  } = component;
  return (
    <TrustbarStyles
      className={clsx(determineBackgroundClass())}
      theme={component?.bgColor}
    >
      <Container>
        <Row className="justify-content-center align-items-center text-center">
          <Col lg={12}>
            <div className="stars">
              <Icon id="five-stars" isImage />
              <p className="social-reviews my-auto">Based on 200+ reviews on</p>
            </div>
          </Col>
          {variant === 'Static' &&
            items &&
            items.map((item, index) => (
              <Col lg="auto" md="auto" sm={12} xs={12} key={`company_${index}`}>
                <span className="company-logo">
                  <Image
                    src={item?.icon?.url}
                    image={item?.icon?.gatsbyImageData}
                    alt={item?.icon?.alt || 'company logo'}
                  />
                </span>
              </Col>
            ))}
          {variant === 'Scrolling' && (
            <Col lg={12}>
              <Marquee pauseOnHover>
                {items &&
                  items.map((item, index) => (
                    <span className="company-logo" key={`company_${index}`}>
                      <Image
                        src={item?.icon?.url}
                        image={item?.icon?.gatsbyImageData}
                        alt={item?.icon?.alt || 'company logo'}
                      />
                    </span>
                  ))}
              </Marquee>
            </Col>
          )}
        </Row>
      </Container>
    </TrustbarStyles>
  );
};
export default Trustbar;
