import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import RichTextWrap from '../../styles/Components/RichTextBlock/RichTextBlockStyles';

const RichTextBlock = ({ data, direct }) => (
  <section>
    <Container className="rich-text-container">
      <Row className="align-items-left justify-content-center mx-0">
        <Col md={8}>
          <RichTextWrap
            dangerouslySetInnerHTML={{
              __html: direct ? data : data?.node?.content[0]?.content,
            }}
          />
        </Col>
      </Row>
    </Container>
  </section>
);

export default RichTextBlock;
