import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Img from '../../utils/OptimizedImage';
import IllustrationStyles from '../../styles/Components/Illustration/IllustrationStyles';

const Illustration = ({ component }) => {
  const { heading, subhead, graphic, caption, backgroundColor } = component;

  return (
    <IllustrationStyles backgroundColor={backgroundColor?.hex}>
      <Container>
        {heading && (
          <Row>
            <h2>{heading}</h2>
          </Row>
        )}
        {subhead && (
          <Row>
            <div
              dangerouslySetInnerHTML={{ __html: subhead }}
              className="subhead"
            />
          </Row>
        )}
        <Row>
          <figure>
            {graphic?.image?.gatsbyImageData ? (
              <Img
                image={graphic?.image?.gatsbyImageData}
                alt={graphic?.internalName}
                title={graphic?.image?.title}
              />
            ) : (
              <Img
                src={graphic?.image?.url}
                alt={graphic?.internalName}
                title={graphic?.image?.title}
              />
            )}
          </figure>
        </Row>
        {caption && (
          <Row>
            <h4>{caption}</h4>
          </Row>
        )}
      </Container>
    </IllustrationStyles>
  );
};

export default Illustration;
