import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ListItems from '../ListItems';
import Img from '../../utils/OptimizedImage';
import GatedListStyles from '../../styles/Components/GatedList/GatedListStyles';

const GatedList = ({ component }) => {
  const { heading, subhead, listBlock, backgroundColor, images } = component;

  return (
    <GatedListStyles backgroundColor={backgroundColor?.hex}>
      <Container>
        <Row className="section-heading">{heading && <h2>{heading}</h2>}</Row>
        <Row className="section-subheading">
          {subhead && (
            <div
              dangerouslySetInnerHTML={{ __html: subhead }}
              className="subhead"
            />
          )}
        </Row>
        <ListItems items={listBlock} spaceBetween="72px" hasTags />
      </Container>
      <figure className="right-poly">
        {images?.image?.gatsbyImageData ? (
          <Img
            image={images?.image?.gatsbyImageData}
            alt={images?.internalName}
            title={images?.image?.title}
          />
        ) : (
          <Img
            src={images?.image?.url}
            alt={images?.internalName}
            title={images?.image?.title}
          />
        )}
      </figure>
      <figure className="left-poly">
        {images?.backgroundImage?.gatsbyImageData ? (
          <Img
            image={images?.backgroundImage?.gatsbyImageData}
            alt={images?.internalName}
            title={images?.backgroundImage?.title}
          />
        ) : (
          <Img
            src={images?.backgroundImage?.url}
            alt={images?.internalName}
            title={images?.backgroundImage?.title}
          />
        )}
      </figure>
    </GatedListStyles>
  );
};

export default GatedList;
