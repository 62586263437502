import React from 'react';
import OptimizedImage from '../../../utils/OptimizedImage';
import { getTag } from '../../../utils/getTag';

const ListItem = ({ component }) => {
  const Heading = component?.headingTag ? getTag(component?.headingTag) : 'p';
  const icon = component?.iconImage
    ? component?.iconImage
    : component?.icon?.image;

  return (
    <div className="list-item">
      {component?.featuredContent === 'icon' && (
        <div className="list-icon">
          {component?.iconImage?.gatsbyImageData ? (
            <OptimizedImage
              image={component?.iconImage?.gatsbyImageData}
              alt="Icon for List Item"
              width="45px"
              height="45px"
            />
          ) : (
            <OptimizedImage
              src={icon?.url}
              alt="Icon for List Item"
              width="45px"
              height="45px"
            />
          )}
        </div>
      )}
      {component?.featuredContent === 'text' && (
        <p
          className="list-featured-text"
          style={{ color: component?.featuredTextColor?.hex }}
        >
          {component?.featuredText}
        </p>
      )}
      <div className="list-text">
        <Heading className="list-heading">{component.heading}</Heading>
        <div
          className="list-subhead"
          dangerouslySetInnerHTML={{ __html: component.subhead }}
        />
      </div>
    </div>
  );
};

export default ListItem;
