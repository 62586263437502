import React from 'react';
import Features from './Features';
import Sidebar from './Sidebar';
import { FeatureContainer } from '../../styles/Components/FeatureComponent/FeatureComponentStyles';

const FeatureComponent = ({ component }) => {
  const { featureTypes } = component;
  return (
    <FeatureContainer>
      <Sidebar featureTypes={featureTypes} />
      <Features featureTypes={featureTypes} />
    </FeatureContainer>
  );
};

export default FeatureComponent;
