import React from 'react';
import loadable from '@loadable/component';

const UseCaseVertical = loadable(() => import('./components/UseCase-Vertical'));
const UseCaseHorizontal = loadable(() =>
  import('./components/UseCase-Horizontal')
);

const UseCaseComponent = ({ component }) => {
  switch (component.variant) {
    case 'horizontal':
      return <UseCaseHorizontal component={component} />;
    case 'vertical':
      return <UseCaseVertical {...component} />;
    default:
      return null;
  }
};

export default UseCaseComponent;
