import React, { useState } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';

const Sidebar = ({ featureTypes }) => {
  const [currentHash, setCurrentHash] = useState('features');
  return (
    <SidebarStyles>
      {featureTypes &&
        featureTypes.map(features => {
          const slug = features.name
            .split(' ')
            .join('-')
            .toLowerCase();
          return (
            <FeatureLink
              to={slug}
              spy
              smooth
              duration={3000}
              exact
              offset={-100}
              active={(currentHash === slug).toString()}
              onClick={() =>
                setCurrentHash(features.name.toLowerCase().toString())
              }
              onSetActive={hash =>
                setCurrentHash(
                  hash
                    .split(' ')
                    .join('-')
                    .toLowerCase()
                )
              }
            >
              {features.name}
            </FeatureLink>
          );
        })}
    </SidebarStyles>
  );
};
export default Sidebar;
export const featureLinkStyles = {
  true: `
      font-weight: bold;
      color: #171E2A;
      &:after {
        position: absolute;
        content: "";
        top: 50%;
        margin: auto;
        right: -24px;
        transform: translateY(-50%)rotate(45deg);
        transform-origin: bottom;
        width: 6px;
        height: 6px;
        border-top: 2px solid #171E2A;
        border-right: 2px solid #171E2A;
        transition: all 0.2s ease;
      }
      &:before {
        position: absolute;
        content: "";
        top:50%;
        left:calc(100% + 13px);
        opacity: 1;
        width: 12px;
        border-bottom: 2px solid #171E2A;
      }
      `,
  false: `
      color: #7B848F;
      &:after {
        position: absolute;
        content: "";
        top: 50%;
        margin: auto;
        right: 0px;
        transform: translateY(-50%)rotate(45deg);
        transform-origin: bottom;
        width: 0px;
        height: 0px;
        border-top: 0px solid #3395ff;
        border-right: 0px solid #3395ff;
        transition: all 0.2s ease;
      }
      &:before {
        position: absolute;
        content: "";
        top:50%;
        left:calc(100% + 13px);
        width: 0;
        opacity:0;
        border-bottom: 2px solid #3395ff;
      }`,
};
export const SidebarStyles = styled.aside`
  grid-area: sidebar;
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 400px;
`;

export const FeatureLink = styled(Link)`
  ${props => featureLinkStyles[props.active]}
  position: relative;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    color: #3395ff;
    &:after {
      right: -24px;
      width: 6px;
      height: 6px;
      border-top: 2px solid #3395ff;
      border-right: 2px solid #3395ff;
    }
    &:before {
      animation-name: grow-right;
      animation-duration: 0.25s;
      animation-fill-mode: forwards;
      @keyframes grow-right {
        0% {
          opacity: 0;
          width: 0;
        }
        100% {
          opacity: 1;
          width: 12px;
        }
      }
    }
  }
`;
