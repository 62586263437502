import React from 'react';

import { colors } from '../../styles/atoms/colors';

import { BackgroundImage } from '../../molecules/Section/Section.styles';
import InlineVideo from '../../molecules/InlineVideo/InlineVideo';
import Section from '../../molecules/Section/Section';

import {
  Heading,
  HeadingWrapper,
  Kicker,
  Subhead,
  VideoWrapper,
  Wrapper,
} from './VideoStyles';

import { getYoutubeId } from '../../utils/getYoutubeId';

import ceraBackground from '../../images/background/bg-cera.png';
import blueBackground from '../../images/background/bg-blue.png';
import charcoalBackground from '../../images/background/bg-charcoal.png';

const VideoComponent = ({
  component: {
    id,
    headingKicker,
    heading,
    subhead,
    videoContent,
    variant = 'Light',
  },
}) => {
  const youtubeId = getYoutubeId(videoContent?.youtubeUrl);
  const bgVariant = {
    Light: { color: 'light', bg: ceraBackground },
    Cera: { color: 'light', bg: ceraBackground },
    Blue: { color: 'blue', bg: blueBackground },
    Charcoal: { color: 'charcoal', bg: charcoalBackground },
  };

  return (
    <Section id={id}>
      <BackgroundImage src={bgVariant[variant].bg} />
      <Wrapper>
        {heading && (
          <HeadingWrapper>
            {headingKicker && (
              <Kicker variant={bgVariant[variant].color}>
                {headingKicker}
              </Kicker>
            )}
            <Heading variant={bgVariant[variant].color}>{heading}</Heading>
            {subhead && (
              <Subhead variant={bgVariant[variant].color}>{subhead}</Subhead>
            )}
          </HeadingWrapper>
        )}
        <VideoWrapper>
          <InlineVideo
            videoId={youtubeId}
            videoImage={videoContent?.videoThumbnail?.image}
            iconColor={colors.primary[500]}
            iconBgColor={colors.base.white}
          />
        </VideoWrapper>
      </Wrapper>
    </Section>
  );
};

export default VideoComponent;
