import styled, { css } from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  align-items: flex-start;
  position: fixed;
  inset: 0px;
  overflow: scroll;
  padding: 64px 0px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  z-index: 999999;

  ${({ showPopup }) =>
    showPopup &&
    `
    opacity: 1;
    pointer-events: auto;
  `}

  ${({ renderInLibrary }) =>
    renderInLibrary &&
    css`
      width: auto;
      height: auto;
      position: relative;
      opacity: 1;
      pointer-events: auto;
    `}

  ${atMinWidth.xl`
    align-items: center;
  `};
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999998;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms ease-in-out;

  ${({ showPopup }) =>
    showPopup &&
    `
    opacity: 1;
    pointer-events: auto;
  `}
`;
