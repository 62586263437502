import { isParagraph } from 'datocms-structured-text-utils';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { StructuredText, renderRule } from 'react-datocms';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Section from '../../molecules/Section/Section';
import { wrapperStyles } from '../../styles/Components/GridComponent/GridComponentStyles';
import { colors } from '../../styles/atoms/colors';
import { getTag } from '../../utils/getTag';
import PricingPlan from './PricingPlan';
import ListItem from './components/ListItem';

const GridComponent = ({
  component: {
    __typename,
    background,
    blackSubhead,
    hasHexes,
    heading,
    headingKicker,
    headingKickerTag,
    headingSize,
    id,
    kickerColor,
    listItems,
    listItemGap,
    subhead,
    subheading,
  },
  slug,
}) => {
  let buttonCount = 0;

  const isDark = background === colors.gray[900];
  const Heading = headingSize ? getTag(headingSize) : 'h2';
  const Kicker = headingKickerTag ? getTag(headingKickerTag) : 'p';

  return (
    <Section
      id={id}
      bgColor={background}
      innerStyle={wrapperStyles(kickerColor?.hex, blackSubhead, isDark)}
      innerClassName={listItems.length > 0 && 'with-list'}
      hasSvgHexes={hasHexes}
    >
      <Container className="heading-info">
        <Row>
          {headingKicker && (
            <Kicker className="heading-kicker">
              {headingKicker.replace('Worldwide', 'Nationwide')}
            </Kicker>
          )}
        </Row>
        <Row>
          {heading && (
            <div className="heading">
              <Heading>{heading}</Heading>
            </div>
          )}
        </Row>
        <Row>
          {subhead && (
            <div
              dangerouslySetInnerHTML={{ __html: subhead }}
              className="subhead"
            />
          )}
          {subheading && subheading?.links?.length > 0 && (
            <StructuredText
              data={subheading}
              customRules={[
                renderRule(isParagraph, ({ children }) => {
                  return children && <p>{children}</p>;
                }),
              ]}
              renderInlineRecord={({ record }) => {
                switch (record.__typename) {
                  case 'DatoCmsCallToAction':
                    // eslint-disable-next-line no-plusplus
                    buttonCount++;
                    return (
                      <div className="cta-btn-section" key={record?.label}>
                        <CallToAction
                          key={record?.id}
                          variant={
                            buttonCount % 2 === 1 ? 'primary' : 'tertiary'
                          }
                          className={
                            record?.label === 'Chat Now' && 'chat-to-us'
                          }
                          value={record?.label}
                          link={record?.url}
                          popupReference={record?.popupReference}
                          icon={record?.icon}
                          size={record?.size}
                          componentName={__typename}
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              }}
            />
          )}
        </Row>
      </Container>
      {slug === 'pricing' && <PricingPlan />}
      {listItems && listItems.length > 0 && (
        <div
          className="list-wrapper"
          style={
            listItemGap
              ? {
                  gap: listItemGap,
                }
              : {}
          }
        >
          {listItems.map(item => (
            <ListItem key={item?.id} component={item} />
          ))}
        </div>
      )}
    </Section>
  );
};

export default GridComponent;
