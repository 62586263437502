import React from 'react';
import Img from '../../utils/OptimizedImage';
import {
  GalleryContainer,
  Heading,
  GalleryWrapper,
  TeamMember,
  ImgWrapper,
} from '../../styles/Components/EmployeeList/EmployeeStyles';

export default function TeamGallery({ component }) {
  const { heading, employees, scrollId } = component;

  return (
    <GalleryContainer id={scrollId}>
      {heading && <Heading>{heading}</Heading>}
      <GalleryWrapper>
        {employees.map(teamMember => {
          return (
            <TeamMember key={teamMember.internalName} xs={6} md={4}>
              <ImgWrapper>
                {teamMember?.headshot?.image?.gatsbyImageData ? (
                  <Img
                    image={teamMember?.headshot?.image?.gatsbyImageData}
                    alt={teamMember?.headshot?.internalName}
                    title={teamMember?.headshot?.image?.title}
                  />
                ) : (
                  <Img
                    src={teamMember?.headshot?.image?.url}
                    alt={teamMember?.headshot?.internalName}
                    title={teamMember?.headshot?.image?.title}
                  />
                )}
              </ImgWrapper>
              {teamMember?.internalName && <h6>{teamMember.internalName}</h6>}
              {teamMember?.role && <p>{teamMember.role}</p>}
            </TeamMember>
          );
        })}
      </GalleryWrapper>
    </GalleryContainer>
  );
}
