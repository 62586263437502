import loadable from '@loadable/component';
import React, { useMemo } from 'react';
import { Backdrop, Container } from './styles/index.styled';
import useExitIntent from './utils/UseExitIntent';
import useExitIntentData from './utils/useExitIntentData';

const FormPopup = loadable(() => import('./variants/FormPopup'));
const ImagePopup = loadable(() => import('./variants/ImagePopup'));

const ExitIntent = ({ component, renderInLibrary }) => {
  const fallbackData = useExitIntentData();
  const data = component || fallbackData;
  const { id, ref } = data;

  const { showPopup, closePopup } = useExitIntent(component?.id);

  const reference = useMemo(() => {
    switch (ref?.__typename) {
      case 'DatoCmsWebsiteForm':
        return (
          <FormPopup
            component={data}
            closePopup={closePopup}
            renderInLibrary={renderInLibrary}
          />
        );
      case 'DatoCmsWebsiteImage':
        return (
          <ImagePopup
            component={data}
            closePopup={closePopup}
            renderInLibrary={renderInLibrary}
          />
        );
      default:
        return null;
    }
  }, [closePopup, data, ref.__typename, renderInLibrary]);

  return (
    <>
      <Container
        id={id || 'exitIntent'}
        showPopup={showPopup}
        renderInLibrary={renderInLibrary}
      >
        {reference}
      </Container>
      <Backdrop onClick={() => closePopup()} showPopup={showPopup} />
    </>
  );
};

export default ExitIntent;
