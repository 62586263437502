import React, { useState } from 'react';
import Hero from '../components/Hero';
import AccordionComponent from '../components/AccordionComponent';
import GridComponent from '../components/GridComponent';
import TestimonialCarousel from '../components/TestimonialCarousel';
import CardDeck from '../components/CardDeck';
import RichTextBlock from '../components/RichTextBlock';
import FeatureComponent from '../components/FeatureComponent';
import JobsComponent from '../components/JobsComponent';
import SwitchComponent from '../components/SwitchComponent';
import ConversionPanel from '../components/ConversionPanel';
import PricingBlockContainer from '../components/PricingBlockContainer';
import Graphic from '../components/Graphic';
import InvestorCardDeck from '../components/InvestorCardDeck';
import EmployeeList from '../components/EmployeeList';
import VideoCarousel from '../components/VideoCarousel/VideoCarousel';
import Illustration from '../components/Illustration';
import GatedList from '../components/GatedList';
import FeaturePanel from '../components/FeaturePanel';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SocialProof from '../components/SocialProof';
import QuoteComponent from '../components/QuoteComponent';
import VideoComponent from '../components/VideoComponent';
import VerticalCarousel from '../components/TestimonialCarousel/VerticalCarousel';
import NoShowCalculator from '../components/NoShowCalculator/noshow-calculator';
import IntegrationsBlock from '../components/IntegrationsBlock';
import UseCase from '../components/UseCase';
import FundingBar from '../components/FundingBar';
import Metrics from '../components/Metrics';
import HistoryComponent from '../components/History';
import ExitIntent from '../components/ExitIntent';
import Trustbar from '../components/Trustbar';
import Modal from '../components/Modal';
import AwardComponent from '../components/AwardComponent';
import TabbedSwitcher from '../components/TabbedSwitcher/TabbedSwitcher';

export default ({ components, id, slug, breadcrumb, isDark }) => {
  const [period, setPeriod] = useState('monthly');
  const isWithMenu = Boolean(components[0]?.menu);

  return (
    <div className="initial-load" id={id}>
      {components.map((component, index) => {
        switch (component?.__typename) {
          case 'DatoCmsComponentHero':
            return (
              <Hero
                component={component}
                key={component.internalName}
                slug={slug}
                breadcrumb={breadcrumb}
                cIndex={index}
                isWithMenu={isWithMenu}
                isDark={isDark}
              />
            );
          case 'DatoCmsAccordionComponent':
            return (
              <AccordionComponent
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsGridComponent':
            if (component?.heading || component?.subhead) {
              return (
                <GridComponent
                  component={component}
                  key={component.internalName}
                  slug={slug}
                />
              );
            }
            break;
          case 'DatoCmsFeatureComponent':
            return (
              <FeatureComponent
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsJobListingBlock':
            return (
              <JobsComponent
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsTestimonialCarouselComponent':
            return component.isVertical ? (
              <VerticalCarousel
                key={component.internalName}
                component={component}
              />
            ) : (
              <TestimonialCarousel
                component={component}
                key={component.internalName}
                slug={slug}
              />
            );
          case 'DatoCmsCardDeckComponent':
            return (
              <CardDeck component={component} key={component.internalName} />
            );
          case 'DatoCmsModularContentComponent':
            return (
              component.content &&
              component?.content.map(content => {
                if (content.__typename === 'DatoCmsRichText') {
                  return (
                    <RichTextBlock
                      data={content.content}
                      direct
                      key={component.internalName}
                    />
                  );
                }
              })
            );
          case 'DatoCmsSwitchbackComponent':
            return (
              <SwitchComponent
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsConversionPanelComponent':
            return (
              <ConversionPanel
                component={component}
                key={component.internalName}
                bgType={slug === 'shops/general-repair' ? 'solid' : 'gradient'}
              />
            );
          case 'DatoCmsPricingBlockContainer':
            return (
              <PricingBlockContainer
                component={component}
                key={component.internalName}
                period={period}
              />
            );
          case 'DatoCmsGraphic':
            return (
              <Graphic component={component} key={component.internalName} />
            );
          case 'DatoCmsInvestorCardDeck':
            return (
              <InvestorCardDeck
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsEmployeeList':
            return (
              <EmployeeList
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsVideoCarouselComponent':
            return (
              <VideoCarousel
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsIllustration':
            return (
              <Illustration
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsGatedList':
            return (
              <GatedList component={component} key={component.internalName} />
            );
          case 'DatoCmsFeaturePanel':
            return (
              <FeaturePanel
                component={component}
                key={component.internalName}
              />
            );
          case 'DatoCmsSocialProofComponent':
            return (
              <SocialProof key={component.internalName} component={component} />
            );
          case 'DatoCmsComponentVideo':
            return (
              <VideoComponent
                key={component.internalName}
                component={component}
              />
            );
          case 'DatoCmsSingleInstanceComponent':
            return <IntegrationsBlock key={component.internalName} />;
          case 'DatoCmsHeaderComponent':
            return (
              isWithMenu && (
                <Header
                  isDark={isDark}
                  key={component.internalName}
                  componentName={component?.__typename}
                  dynamicMenu={component.menu}
                  dynamicCallToAction={component.callToAction}
                />
              )
            );
          case 'DatoCmsComponentTestimonial':
            return (
              <QuoteComponent
                key={component.internalName}
                component={component}
              />
            );
          case 'DatoCmsFooterComponent':
            return <Footer key={component.internalName} />;
          case 'DatoCmsWebsiteForm':
            if (component?.internalName.includes('No Show Calculator')) {
              return (
                <NoShowCalculator
                  key={component.internalName}
                  component={component}
                />
              );
            }
            return null;
          case 'DatoCmsComponentUseCase':
            return (
              <UseCase key={component.internalName} component={component} />
            );
          case 'DatoCmsComponentFundingBar':
            return (
              <FundingBar key={component.internalName} component={component} />
            );
          case 'DatoCmsMetricsComponent':
            return (
              <Metrics key={component.internalName} component={component} />
            );
          case 'DatoCmsComponentHistory':
            return (
              <HistoryComponent
                key={component.internalName}
                component={component}
              />
            );
          case 'DatoCmsExitIntentComponent':
            return (
              <ExitIntent
                key={component.internalName}
                component={component}
                renderInLibrary
              />
            );
          case 'DatoCmsTrustBarComponent':
            return (
              <Trustbar key={component.internalName} component={component} />
            );
          case 'DatoCmsVideoModalComponent':
            return <Modal show reference={component} library />;
          case 'DatoCmsComponentAward':
            return (
              <AwardComponent
                key={component.internalName}
                component={component}
              />
            );
          case 'DatoCmsComponentTabbedSwitcher':
            return (
              <TabbedSwitcher
                component={component}
                key={component.internalName}
              />
            );
          default:
            break;
        }
      })}
    </div>
  );
};
